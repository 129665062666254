<template>
  <div class="new-import">
    <div v-if="titleShow" class="back-view">
      <HeaderBack :title="title" @back="$emit('goBack')" @click.native="clickBack" />
    </div>
    <div class="upload-file">
      <div class="top_model">
        <p>1.下载标准模板<span>根据提示信息完善表格内容</span></p>
        <a-button antprefix="download" @click="downLoadFile">
          下载模板
        </a-button>
      </div>
      <div class="upload_main">
        <div class="upload_title">
          <div class="upload_main_p">2.上传准备好的表格内容</div>
          <a-radio-group
            v-if="showThree"
            v-model="matching"
            :options="matchingWay"
            @change="matchingChange"
          />
        </div>
        <div id="upLoad">
          <a-upload-dragger
            name="file"
            :multiple="multiple"
            accept=".xlsx"
            list-type="picture"
            :show-upload-list="showUpdateList"
            :file-list="fileList"
            :before-upload="beforeUpload"
            :custom-request="() => customRequest('resolve')"
            :disabled="uploadContentStatus === 'ing'"
          >
            <div class="container">
              <div v-if="uploadContentStatus === 'before'">
                <p class="ant-upload-drag-icon">
                  <img
                    class="xlsx_img"
                    src="@/assets/img/upload/grayXlsx.png"
                    alt=""
                  >
                </p>
                <p class="ant-upload-text">
                  将文件拖拽到此处，或 <a>点击上传</a>
                </p>
                <p class="ant-upload-hint">只能上传xlsx文件格式，且不超过20M</p>
              </div>
              <div v-else-if="uploadContentStatus === 'ing'" class="loading">
                <a-spin>
                  <a-icon
                    slot="indicator"
                    type="loading"
                    style="font-size: 24px"
                    spin
                  />
                </a-spin>
                <div v-if="schedule < 100">正在上传{{ schedule }}%</div>
                <div v-else>
                  正在解析...
                </div>
              </div>
              <div
                v-else-if="
                  uploadContentStatus === 'ok' ||
                    uploadContentStatus === 'final' ||
                    uploadContentStatus === 'error'
                "
              >
                <p class="ant-upload-drag-icon">
                  <img class="xlsx_img" src="@/assets/img/xlsx.png" alt="">
                </p>
                <p class="ant-upload-text">{{ fileInfo.name }}</p>
                <a class="ant-upload-hin">重新上传</a>
              </div>
              <div v-else-if="uploadContentStatus === 'dragover'">
                <p>松开鼠标上传文件</p>
              </div>
            </div>
          </a-upload-dragger>
        </div>
      </div>
    </div>
    <div v-if="isTableShow" class="title">
      <div v-for="(table,index) in tableDataList" :key="`table${index}`">
        <Extable :table-data="table" />
      </div>
    </div>
    <div ref="confirmBtn" class="confirm_btn" :style="{width: domInfo.width + 'px', left: leftTop[0] + 'px', right: rightBottom[0] + 'px'}">
      <a-button
        :disabled="uploadContentStatus !== 'final'"
        type="primary"
        @click="confirmUpLoad"
      >确认上传</a-button>
    </div>
  </div>
</template>

<script>
import {
  importTaxFilingInfoList,
  importTaxpayStaffPaymentInfo,
  getImportTemplate
} from '@/services/wage/tax'
import {
  exportBatchSetTemplate,
  batchSetFixedSalary,
  exportBatchUpdateTemplate,
  batchUpdateFixedSalary } from '@/services/wage/wageArchives'
import {
  batchFixedSalary,
  batchChangeSalary,
  exportSalaryCalcBatchTemplate,
  batchSetSalaryCalc,
  salaryIndexItemGetTemplate,
  salaryIndexItemImportIndex
} from '@/services/wage/wagePayroll'
import {
  exportBatchSupplyTemplateApi,
  batchImportSupplyApi,
  exportBatchBillTemplateApi,
  batchImportBillApi
} from '@/services/insurance/bill.js'
import {
  exportBatchInsureTemplate,
  batchImportInsureScheme
} from '@/services/insurance/archives'
import { downLoadTemplate, batchImportStaff } from '@/services/user/roster.js'
import { batchImportOrg } from '@/services/organization/framework.js'
import {
  exportBatchSupplyPayTemplate,
  batchImportSupplyPay
} from '@/services/insurance/supplementary'
import { exportStaffYearendBounsTemplate, importStaffYearendBouns } from '@/services/wage/yearendBouns.js'
const matchingWay = [
  { label: '员工姓名', value: 'staffName' },
  { label: '证件号码', value: 'idNum' },
  { label: '工号', value: 'staffNum' }
]

export default {
  name: 'NewImport',
  components: {
    Extable: () => import('./newexceptionTable.vue'),
    HeaderBack: () => import('@/components/headerBack')
    // AttendanceShift: () => import('./attendanceShift.vue')
  },
  props: {
    // 下载模板的模板id
    modelId: {
      type: String,
      default: '1526856910525984769'
    },
    // 头部展示的 文案
    title: {
      type: String,
      default: ''
    },
    // 是否支持多文件上传
    multiple: {
      type: Boolean,
      default: false
    },
    showUpdateList: {
      type: Boolean,
      default: true
    },
    // 文件列表
    fileList: {
      type: Array,
      default: () => []
    },
    // 限制文件大小  默认最大20MB
    fileSize: {
      type: Number,
      default: 20
    },
    importTemplate: {
      type: String,
      default: ''
    },
    extraParams: {
      type: Object,
      default: () => {}
    },
    billParam: {
      type: Object,
      default: () => {}
    },
    // 是否展示3选项组
    showThree: {
      type: Boolean,
      default: true
    },
    // 年终奖id
    bounsId: {
      type: [String, Number],
      default: ''
    },
    titleShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fileInfo: {}, // file文件
      // 上传状态 before 未上传
      // ing 上传中
      // ok 上传完成
      // final上传完成且校验通过
      // error上传报错切换匹配方式可再次上传
      uploadContentStatus: 'before',
      schedule: 0, // 上传进度
      matchingWay,
      matching: 'staffName',
      isTableShow: false,
      oldUploadContentStatus: 'before', // 记录上一步状态
      statusArr: ['before', 'ok', 'final', 'error'],
      // 表格信息
      tableDataList: [],
      UPLOADHEIGHT: 172, // 上传组件高度
      leftTop: [],
      rightBottom: [],
      domInfo: {}
    }
  },
  watch: {
    uploadContentStatus(value, oldval) {
      if ((this.statusArr.indexOf(value) < this.statusArr.indexOf(oldval))) {
        this.oldUploadContentStatus = oldval
      } else if (oldval === 'ing') {
        this.oldUploadContentStatus = 'before'
      }
    }
  },
  mounted() {
    this.coordinate()
    this.getCoordinate()
  },
  methods: {
    getCoordinate() {
      const box = document.getElementById('upLoad')
      const dom = box.getBoundingClientRect()
      this.leftTop = [dom.x, dom.y]
      this.rightBottom = [dom.x + dom.width, dom.y + this.UPLOADHEIGHT]
      this.domInfo = dom
    },
    coordinate() {
      addEventListener('dragover', this.dragoverfun)
      addEventListener('drop', this.dragendfun)
    },
    clickBack() {
      removeEventListener('dragover', this.dragoverfun)
      removeEventListener('drop', this.dragendfun)
    },
    dragoverfun(event) {
      if (this.leftTop[1] <= event.pageY && this.rightBottom[1] >= event.pageY && this.leftTop[0] <= event.pageX && this.rightBottom[0] >= event.pageX) {
        this.uploadContentStatus = 'dragover'
      } else if (this.uploadContentStatus !== 'error') {
        this.uploadContentStatus = this.oldUploadContentStatus
      }
    },
    dragendfun() {
      if (this.uploadContentStatus !== 'error') {
        this.uploadContentStatus = this.oldUploadContentStatus
      }
    },
    // 下载文件模板
    downLoadFile() {
      // 批量定薪下载模板
      if (this.importTemplate === 'batchSetFixedSalary' || this.importTemplate === 'batchFixedSalary') {
        exportBatchSetTemplate()
      }
      // 批量调薪下载模板
      if (this.importTemplate === 'batchUpdateFixedSalary' || this.importTemplate === 'batchChangeSalary') {
        exportBatchUpdateTemplate()
      }
      // 导出明细的模板
      if (this.importTemplate === 'salaryIndexItemImportIndex') {
        salaryIndexItemGetTemplate(this.extraParams)
      }
      // 导出薪资项模板
      if (this.importTemplate === 'batchSetSalaryCalc') {
        exportSalaryCalcBatchTemplate(this.extraParams)
      }
      if (this.importTemplate === 'exportBatchSupply') {
        exportBatchSupplyTemplateApi()
      }
      // 下载员工在缴参保方案模板
      if (this.importTemplate === 'socialSecurityArchives') {
        exportBatchInsureTemplate()
      }
      // 下载批量补缴调差模板
      if (this.importTemplate === 'supplementaryPayment') {
        exportBatchSupplyPayTemplate()
      }
      // 下载人员报送模版
      if (this.importTemplate === 'importTaxFilingInfoList') {
        getImportTemplate({ templateCode: 'taxpay_subject_staff', ...this.extraParams, pageNum: 1, pageSize: -1 })
      }
      // 下载个税申报模板
      if (this.importTemplate === 'importTaxpayStaffPaymentInfo') {
        getImportTemplate({ templateCode: 'taxpay_payment', ...this.extraParams, pageNum: 1, pageSize: -1 })
      }// 年终奖批量导入
      if (this.importTemplate === 'yearBouns') {
        exportStaffYearendBounsTemplate({ yearendBounsId: this.bounsId })
      }
      if (this.importTemplate === 'importUser' || this.importTemplate === 'importOrg') {
        downLoadTemplate({ id: this.modelId })
      }
      if (this.importTemplate === 'socialSecurityBill') {
        exportBatchBillTemplateApi()
      }
      // #region
      // 考勤模块
      // if (this.importTemplate === 'append_punch' ||
      //     this.importTemplate === 'travel' ||
      //     this.importTemplate === 'change_shift' ||
      //     this.importTemplate === 'exchange_shift' ||
      //     this.importTemplate === 'overtime' ||
      //     this.importTemplate === 'vacation_adjustment_grant' ||
      //     this.importTemplate === 'vacation_adjustment_deduct' ||
      //     this.importTemplate === 'vacation' ||
      //     this.importTemplate === 'outside' ||
      //     this.importTemplate === 'clock'
      // ) {
      //   getImportTemplate({ templateCode: this.importTemplate })
      // }
      // 批量考勤排班表下载
      // if (this.importTemplate === 'shift') {
      //   this.$refs.attendanceShift.visible = true
      // }
      // #endregion
    },
    // 上传前校验相关
    beforeUpload(file) {
      this.fileInfo = file
      if (this.uploadContentStatus === 'ing') {
        return
      }
      // this.$refs?.extable.clear()
      const isXlsx =
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (!isXlsx) {
        this.$message.error('文件类型错误！只支持xlsx文件！')
        return false
      }
      const isLtM = file.size / 1024 / 1024 < this.fileSize
      if (!isLtM) {
        this.$message.error(`文件大小不能超过${this.fileSize}M！`)
        return false
      }
      this.uploadContentStatus = 'ing'
    },
    // 自定义上传的方法
    async customRequest(type = 'resolve') {
      this.isTableShow = false
      const { extraParams } = this
      const extraParamsKeys = extraParams ? Object.keys(extraParams) : []
      const xlsx = this.fileInfo
      const formData = new FormData()
      console.log('formData', xlsx)
      // append进额外的参数
      if (extraParamsKeys.length > 0) {
        extraParamsKeys.forEach(item => {
          const targetItem = extraParams[item]
          formData.append(item, targetItem)
        })
      }
      formData.append('file', xlsx)
      formData.append('type', type)
      formData.append('matchRule', this.matching)
      const config = {
        loading: false,
        onUploadProgress: progressEvent => {
          const complete = (progressEvent.loaded / progressEvent.total) * 100
          this.schedule = Math.trunc(complete)
        }
      }
      let res
      try {
        switch (this.importTemplate) {
          case 'importTaxFilingInfoList': // 导入员工报送信息
            res = await importTaxFilingInfoList(formData, config)
            break
          case 'importTaxpayStaffPaymentInfo':
            res = await importTaxpayStaffPaymentInfo(formData, config)
            break
            // 档案模块儿
          case 'batchSetFixedSalary': // 人员批量定薪
            res = await batchSetFixedSalary(formData, config)
            break
          case 'batchUpdateFixedSalary': // 人员批量调薪
            res = await batchUpdateFixedSalary(formData, config)
            break
            // 计薪模块儿
          case 'batchFixedSalary': // 批量定薪
            res = await batchFixedSalary(formData, config)
            break
          case 'salaryIndexItemImportIndex': // 导入明细指标项
            res = await salaryIndexItemImportIndex(formData, config)
            break
          case 'batchChangeSalary': // 批量调薪
            res = await batchChangeSalary(formData, config)
            break
          case 'batchSetSalaryCalc': // 导入薪资合计
            res = await batchSetSalaryCalc(formData, config)
            break
          case 'exportBatchSupply': //  补缴调差 -待调整
            formData.append('insureCompanyId', this.billParam.insureCompanyId)
            formData.append('insureMonth', this.billParam.month)
            res = await batchImportSupplyApi(formData, config)
            break
          case 'socialSecurityArchives': // 导入社保档案
            res = await batchImportInsureScheme(formData, config)
            break
          case 'importUser': // 员工批量导入
            res = await batchImportStaff(formData, config)
            break
          case 'importOrg': // 批量导入组织
            res = await batchImportOrg(formData, config)
            break
          case 'supplementaryPayment': // 导入补缴调差
            res = await batchImportSupplyPay(formData, config)
            break
          case 'yearBouns': // 年终奖
            formData.append('yearendBounsId', this.bounsId)
            res = await importStaffYearendBouns(formData, config)
            break
          case 'socialSecurityBill'://
            console.log(formData)
            formData.append('insureCompanyId', this.billParam.insureCompanyId)
            formData.append('insureMonth', this.billParam.insureMonth)
            res = await batchImportBillApi(formData, config)
            break
            // #region
            // 考勤模块
            // case 'clock': // 打卡
            //   res = await batchImportClock(formData, config)
            //   break
            // case 'append_punch': // 补卡
            //   res = await batchImportApplyAppendPunch(formData, config)
            //   break
            // case 'vacation': // 请假
            //   res = await batchImportApplyVacation(formData, config)
            //   break
            // case 'outside': // 外出
            //   res = await batchImportApplyOutside(formData, config)
            //   break
            // case 'travel': // 出差
            //   res = await batchImportApplyTravel(formData, config)
            //   break
            // case 'overtime': // 加班
            //   res = await batchImportApplyOvertime(formData, config)
            //   break
            // case 'shift': // 排班表
            //   res = await batchImportShift(formData, config)
            //   break
            // case 'change_shift': // 调班
            //   res = await batchImportApplyChangeShift(formData, config)
            //   break
            // case 'exchange_shift': // 换班
            //   res = await batchImportApplyChangeShift(formData, config)
            //   break
            // case 'vacation_adjustment_grant': // 批量发放假期
            //   res = await batchImportGrantVacationAdjustment(formData, config)
            //   break
            // case 'vacation_adjustment_deduct': // 批量扣减假期
            //   res = await batchImportDeductVacationAdjustment(formData, config)
            //   break
            // #endregion
          default:
            res = {}
        }
      } catch (err) {
        this.uploadContentStatus = 'error'
        return
      }
      this.afterUpload(res, type)
      if (type === 'save' && this.tableDataList?.every((line) => line.errorList.length === 0)) {
        this.$message.success('导入信息成功')
        // 批量导入员工成功后刷新上一页顶部数据
        this.$emit('getStaffStatistics')
        this.$emit('goBack')
      }
    },
    // 上传解析完成处理返回结果
    afterUpload({ code, data }, type) {
      if (code === 100) {
        // 判断data是否为数组
        if (Array.isArray(data)) {
          this.tableDataList = data
        } else {
          this.tableDataList[0] = data
        }
        // 循环处理表格数据
        this.tableDataList.forEach((data) => {
          const { errorList = [], dataList = [], headColumn = [] } = data
          this.uploadContentStatus = 'ok'
          if (!headColumn || headColumn.length === 0) {
            this.isTableShow = false
            this.uploadContentStatus = 'error'
            return this.$message.warning('文件中存在不符合规则的数据，请修改后重新导入')
          } else {
            this.isTableShow = true
          }
          if (errorList.length > 0) {
            this.$message.warning('文件中存在不符合规则的数据，请修改后重新导入')
            this.uploadContentStatus = 'error'
            // this.$refs.extable.initTable(errorList, headColumn)
            return
          }
          if (type !== 'save' && this.importTemplate === 'yearBouns' && dataList.length === 0) {
            this.$message.warning('文件内容不能为空')
            return
          }
          if (type !== 'save') {
            this.$message.success('校验文件无异常数据')
          }
          this.uploadContentStatus = 'final'
          // this.$refs.extable.initTable(dataList, headColumn)
        })
      } else {
        this.$message.error('导入失败')
        this.uploadContentStatus = 'before'
      }
    },
    // 确认上传
    confirmUpLoad() {
      this.customRequest('save')
    },
    matchingChange() {
      const { uploadContentStatus } = this
      if (uploadContentStatus === 'error') {
        this.uploadContentStatus = 'final'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.new-import {
  width: 100%;
  // padding-bottom: 80px;
}
.upload-file {
  padding: 32px 20px 0 20px;
  .top_model {
    background-color: @gray-4;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 20px;
    p {
      margin: 0;
      padding: 0;
      padding-bottom: 5px;
      color: @gray-10;
      span {
        color: @gray-8;
        margin-left: 10px;
      }
    }
  }
  .container {
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload_main {
    background-color: @gray-4;
    border-radius: 4px;
    padding: 10px 20px;
    .upload_title {
      display: flex;
      padding: 10px 0 10px;
      align-items: center;
    }
    .upload_main_p {
      color: @gray-10;
      padding-right: 10px;
    }
    .ant-upload-text {
      margin-right: 5px;
      font-size: 14px;
    }
    .ant-upload-hint {
      margin-top: 6px;
      color: @gray-7;
    }
    a {
      // text-decoration-line: underline;
      padding-bottom: 1px;
      border-bottom: 1px @sc-primary-100 solid;
    }
    .xlsx_img {
      width: 50px;
      height: 60px;
    }
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.confirm_btn {
  padding: 8px 0;
  position: fixed;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.matching_model {
  background-color: @gray-4;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 20px;
  .topText {
    color: @sc-grey-80;
  }
}
.close_icon {
  color: @gray-10;
  width: 10px;
  height: 10px;
}
/deep/ .ant-btn-sm {
  height: 30px;
}
/deep/ .ant-upload.ant-upload-drag {
  background-color: @sc-greyBg-10;
}
/deep/.ant-upload:hover {
  background: @sc-primary-10;
}
/deep/.ant-upload-list-item-thumbnail {
  top: 13px;
}
/deep/.ant-upload-list-item-card-actions .anticon {
  padding-top: 6px;
}
/deep/.ant-upload-list-item-info {
  line-height: 90px;
}
/deep/.ant-upload.ant-upload-drag {
  border: 1px dashed @sc-primary-100;
}
.title {
  padding: 20px;
}
.icon_img {
  width: 16px;
  height: 16px;
  margin-right: 3px;
  vertical-align: top;
}

/deep/.anticon-download{
  color: @sc-primary-100;
}
</style>
