var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-import" }, [
    _vm.titleShow
      ? _c(
          "div",
          { staticClass: "back-view" },
          [
            _c("HeaderBack", {
              attrs: { title: _vm.title },
              on: {
                back: function ($event) {
                  return _vm.$emit("goBack")
                },
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.clickBack($event)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "upload-file" }, [
      _c(
        "div",
        { staticClass: "top_model" },
        [
          _vm._m(0),
          _c(
            "a-button",
            {
              attrs: { antprefix: "download" },
              on: { click: _vm.downLoadFile },
            },
            [_vm._v(" 下载模板 ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "upload_main" }, [
        _c(
          "div",
          { staticClass: "upload_title" },
          [
            _c("div", { staticClass: "upload_main_p" }, [
              _vm._v("2.上传准备好的表格内容"),
            ]),
            _vm.showThree
              ? _c("a-radio-group", {
                  attrs: { options: _vm.matchingWay },
                  on: { change: _vm.matchingChange },
                  model: {
                    value: _vm.matching,
                    callback: function ($$v) {
                      _vm.matching = $$v
                    },
                    expression: "matching",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { attrs: { id: "upLoad" } },
          [
            _c(
              "a-upload-dragger",
              {
                attrs: {
                  name: "file",
                  multiple: _vm.multiple,
                  accept: ".xlsx",
                  "list-type": "picture",
                  "show-upload-list": _vm.showUpdateList,
                  "file-list": _vm.fileList,
                  "before-upload": _vm.beforeUpload,
                  "custom-request": function () {
                    return _vm.customRequest("resolve")
                  },
                  disabled: _vm.uploadContentStatus === "ing",
                },
              },
              [
                _c("div", { staticClass: "container" }, [
                  _vm.uploadContentStatus === "before"
                    ? _c("div", [
                        _c("p", { staticClass: "ant-upload-drag-icon" }, [
                          _c("img", {
                            staticClass: "xlsx_img",
                            attrs: {
                              src: require("@/assets/img/upload/grayXlsx.png"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("p", { staticClass: "ant-upload-text" }, [
                          _vm._v(" 将文件拖拽到此处，或 "),
                          _c("a", [_vm._v("点击上传")]),
                        ]),
                        _c("p", { staticClass: "ant-upload-hint" }, [
                          _vm._v("只能上传xlsx文件格式，且不超过20M"),
                        ]),
                      ])
                    : _vm.uploadContentStatus === "ing"
                    ? _c(
                        "div",
                        { staticClass: "loading" },
                        [
                          _c(
                            "a-spin",
                            [
                              _c("a-icon", {
                                staticStyle: { "font-size": "24px" },
                                attrs: {
                                  slot: "indicator",
                                  type: "loading",
                                  spin: "",
                                },
                                slot: "indicator",
                              }),
                            ],
                            1
                          ),
                          _vm.schedule < 100
                            ? _c("div", [
                                _vm._v("正在上传" + _vm._s(_vm.schedule) + "%"),
                              ])
                            : _c("div", [_vm._v(" 正在解析... ")]),
                        ],
                        1
                      )
                    : _vm.uploadContentStatus === "ok" ||
                      _vm.uploadContentStatus === "final" ||
                      _vm.uploadContentStatus === "error"
                    ? _c("div", [
                        _c("p", { staticClass: "ant-upload-drag-icon" }, [
                          _c("img", {
                            staticClass: "xlsx_img",
                            attrs: {
                              src: require("@/assets/img/xlsx.png"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("p", { staticClass: "ant-upload-text" }, [
                          _vm._v(_vm._s(_vm.fileInfo.name)),
                        ]),
                        _c("a", { staticClass: "ant-upload-hin" }, [
                          _vm._v("重新上传"),
                        ]),
                      ])
                    : _vm.uploadContentStatus === "dragover"
                    ? _c("div", [_c("p", [_vm._v("松开鼠标上传文件")])])
                    : _vm._e(),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm.isTableShow
      ? _c(
          "div",
          { staticClass: "title" },
          _vm._l(_vm.tableDataList, function (table, index) {
            return _c(
              "div",
              { key: "table" + index },
              [_c("Extable", { attrs: { "table-data": table } })],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      {
        ref: "confirmBtn",
        staticClass: "confirm_btn",
        style: {
          width: _vm.domInfo.width + "px",
          left: _vm.leftTop[0] + "px",
          right: _vm.rightBottom[0] + "px",
        },
      },
      [
        _c(
          "a-button",
          {
            attrs: {
              disabled: _vm.uploadContentStatus !== "final",
              type: "primary",
            },
            on: { click: _vm.confirmUpLoad },
          },
          [_vm._v("确认上传")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("1.下载标准模板"),
      _c("span", [_vm._v("根据提示信息完善表格内容")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }